export const SET_AUTH = 'SET_AUTH';
export const FETCH_FOLDERS_ATTEMPTED = 'FETCH_FOLDERS_ATTEMPTED';
export const FETCH_FOLDERS_SUCCEEDED = 'FETCH_FOLDERS_SUCCEEDED';
export const FETCH_FOLDERS_FAILED = 'FETCH_FOLDERS_FAILED';
export const ADD_FOLDER_ATTEMPTED = 'ADD_FOLDER_ATTEMPTED';
export const ADD_FOLDER_SUCCEEDED = 'ADD_FOLDER_SUCCEEDED';
export const ADD_FOLDER_FAILED = 'ADD_FOLDER_FAILED';
export const DELETE_FOLDER_ATTEMPTED = 'DELETE_FOLDER_ATTEMPTED';
export const DELETE_FOLDER_SUCCEEDED = 'DELETE_FOLDER_SUCCEEDED';
export const DELETE_FOLDER_FAILED = 'DELETE_FOLDER_FAILED';
export const MOVE_FOLDER_ATTEMPTED = 'MOVE_FOLDER_ATTEMPTED';
export const MOVE_FOLDER_SUCCEEDED = 'MOVE_FOLDER_SUCCEEDED';
export const MOVE_FOLDER_FAILED = 'MOVE_FOLDER_FAILED';
export const RENAME_FOLDER_ATTEMPTED = 'RENAME_FOLDER_ATTEMPTED';
export const RENAME_FOLDER_SUCCEEDED = 'RENAME_FOLDER_SUCCEEDED';
export const RENAME_FOLDER_FAILED = 'RENAME_FOLDER_FAILED';
export const BULK_DELETE_ATTEMPTED = 'BULK_DELETE_ATTEMPTED';
export const BULK_DELETE_SUCCEEDED = 'BULK_DELETE_SUCCEEDED';
export const BULK_DELETE_FAILED = 'BULK_DELETE_FAILED';
export const BULK_MOVE_ATTEMPTED = 'BULK_MOVE_ATTEMPTED';
export const BULK_MOVE_SUCCEEDED = 'BULK_MOVE_SUCCEEDED';
export const BULK_MOVE_FAILED = 'BULK_MOVE_FAILED';
export const FETCH_MORE_FILES_FAILED = 'FETCH_MORE_FILES_FAILED';
export const FETCH_SINGLE_FILE_ATTEMPTED = 'FETCH_SINGLE_FILE_ATTEMPTED';
export const FETCH_SINGLE_FILE_SUCCESS = 'FETCH_SINGLE_FILE_SUCCESS';
export const FETCH_SINGLE_FILE_FAILED = 'FETCH_SINGLE_FILE_FAILED';
export const SEARCH_FILES_ATTEMPTED = 'SEARCH_FILES_ATTEMPTED';
export const SEARCH_FILES_SUCCESS = 'SEARCH_FILES_SUCCESS';
export const SEARCH_FILES_FAILED = 'SEARCH_FILES_FAILED';
export const SEARCH_FILES_AND_FOLDERS_ATTEMPTED = 'SEARCH_FILES_AND_FOLDERS_ATTEMPTED';
export const SEARCH_FILES_AND_FOLDERS_SUCCESS = 'SEARCH_FILES_AND_FOLDERS_SUCCESS';
export const SEARCH_FILES_AND_FOLDERS_FAILED = 'SEARCH_FILES_AND_FOLDERS_FAILED';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_ATTEMPTED = 'UPLOAD_FILE_ATTEMPTED';
export const UPLOAD_FILE_SUCCEEDED = 'UPLOAD_FILE_SUCCEEDED';
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED';
export const BULK_UPLOAD_FILES_ATTEMPTED = 'BULK_UPLOAD_FILES_ATTEMPTED';
export const UPLOAD_BATCH_EXCEEDED_LIMIT = 'UPLOAD_BATCH_EXCEEDED_LIMIT';
export const REPLACE_FILE_PROGRESS = 'REPLACE_FILE_PROGRESS';
export const REPLACE_FILE_ATTEMPTED = 'REPLACE_FILE_ATTEMPTED';
export const REPLACE_FILE_SUCCEEDED = 'REPLACE_FILE_SUCCEEDED';
export const REPLACE_FILE_FAILED = 'REPLACE_FILE_FAILED';
export const RECALCULATE_IMAGE_DIMENSIONS_SUCCESS = 'RECALCULATE_IMAGE_DIMENSIONS_SUCCESS';
export const DELETE_FILE_ATTEMPTED = 'DELETE_FILE_ATTEMPTED';
export const DELETE_FILE_SUCCEEDED = 'DELETE_FILE_SUCCEEDED';
export const DELETE_FILE_FAILED = 'DELETE_FILE_FAILED';
export const ARCHIVE_FILE_ATTEMPTED = 'ARCHIVE_FILE_ATTEMPTED';
export const ARCHIVE_FILE_SUCCEEDED = 'ARCHIVE_FILE_SUCCEEDED';
export const ARCHIVE_FILE_FAILED = 'ARCHIVE_FILE_FAILED';
export const UNARCHIVE_FILE_ATTEMPTED = 'UNARCHIVE_FILE_ATTEMPTED';
export const UNARCHIVE_FILE_SUCCEEDED = 'UNARCHIVE_FILE_SUCCEEDED';
export const UNARCHIVE_FILE_FAILED = 'UNARCHIVE_FILE_FAILED';
export const MOVE_FILE_ATTEMPTED = 'MOVE_FILE_ATTEMPTED';
export const MOVE_FILE_SUCCEEDED = 'MOVE_FILE_SUCCEEDED';
export const MOVE_FILE_FAILED = 'MOVE_FILE_FAILED';
export const RENAME_FILE_ATTEMPTED = 'RENAME_FILE_ATTEMPTED';
export const RENAME_FILE_SUCCEEDED = 'RENAME_FILE_SUCCEEDED';
export const RENAME_FILE_FAILED = 'RENAME_FILE_FAILED';
export const UPDATE_FILE_META_ATTEMPTED = 'UPDATE_FILE_META_ATTEMPTED';
export const UPDATE_FILE_META_SUCCEEDED = 'UPDATE_FILE_META_SUCCEEDED';
export const UPDATE_FILE_META_FAILED = 'UPDATE_FILE_META_FAILED';
export const UPDATE_FILE_EXPIRATION_ATTEMPTED = 'UPDATE_FILE_EXPIRATION_ATTEMPTED';
export const UPDATE_FILE_EXPIRATION_SUCCEEDED = 'UPDATE_FILE_EXPIRATION_SUCCEEDED';
export const UPDATE_FILE_EXPIRATION_FAILED = 'UPDATE_FILE_EXPIRATION_FAILED';
export const FETCH_PORTAL_META_SUCCEEDED = 'FETCH_PORTAL_META_SUCCEEDED';
export const FETCH_PORTAL_META_FAILED = 'FETCH_PORTAL_META_FAILED';
export const UPDATE_PORTAL_META_ATTEMPTED = 'UPDATE_PORTAL_META_ATTEMPTED';
export const UPDATE_PORTAL_META_SUCCEEDED = 'UPDATE_PORTAL_META_SUCCEEDED';
export const UPDATE_PORTAL_META_FAILED = 'UPDATE_PORTAL_META_FAILED';
export const CREATE_VIDEO_PLAYERID_ATTEMPTED = 'CREATE_VIDEO_PLAYERID_ATTEMPTED';
export const CREATE_VIDEO_PLAYERID_SUCCEEDED = 'CREATE_VIDEO_PLAYERID_SUCCEEDED';
export const CREATE_VIDEO_PLAYERID_FAILED = 'CREATE_VIDEO_PLAYERID_FAILED';
export const SOFT_DELETE_VIDEO_PLAYERID_ATTEMPTED = 'SOFT_DELETE_PLAYERID_ATTEMPTED';
export const SOFT_DELETE_VIDEO_PLAYERID_FAILED = 'SOFT_DELETE_VIDEO_PLAYERID_FAILED';
export const SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED = 'SOFT_DELETE_VIDEO_PLAYERID_SUCCEEDED';
export const CANVA_INIT_ATTEMPTED = 'CANVA_INIT_ATTEMPTED';
export const CANVA_INIT_SUCCEEDED = 'CANVA_INIT_SUCCEEDED';
export const CANVA_INIT_FAILED = 'CANVA_INIT_FAILED';
export const DOWNLOAD_FROM_CANVA_ATTEMPTED = 'DOWNLOAD_FROM_CANVA_ATTEMPTED';
export const DOWNLOAD_FROM_CANVA_SUCCEEDED = 'DOWNLOAD_FROM_CANVA_SUCCEEDED';
export const DOWNLOAD_FROM_CANVA_FAILED = 'DOWNLOAD_FROM_CANVA_FAILED';
export const REMOVE_CANVA_ID_SUCCEEDED = 'REMOVE_CANVA_ID_SUCCEEDED';
export const DOWNLOAD_FROM_EXTERNAL_URL_ATTEMPTED = 'DOWNLOAD_FROM_EXTERNAL_URL_ATTEMPTED';
export const DOWNLOAD_FROM_EXTERNAL_URL_SUCCEEDED = 'DOWNLOAD_FROM_EXTERNAL_URL_SUCCEEDED';
export const VIDEO_THUMBNAIL_UPDATE_ATTEMPTED = 'VIDEO_THUMBNAIL_UPDATE_ATTEMPTED';
export const VIDEO_THUMBNAIL_UPDATE_FAILED = 'VIDEO_THUMBNAIL_UPDATE_FAILED';
export const VIDEO_THUMBNAIL_UPDATE_SUCCEEDED = 'VIDEO_THUMBNAIL_UPDATE_SUCCEEDED';
export const GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_ATTEMPTED = 'GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_ATTEMPTED';
export const GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_FAILED = 'GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_FAILED';
export const GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED = 'GENERATE_AND_UPDATE_VIDEO_THUMBNAIL_SUCCEEDED';
export const FETCH_LIMITS_SUCCEEDED = 'FETCH_LIMITS_SUCCEEDED';
export const CONFIRM_VIDEO_UPLOAD_WITHOUT_EMBED = 'CONFIRM_VIDEO_UPLOAD_WITHOUT_EMBED';
export const DECREASE_VIDEO_QUANTITY_USED = 'DECREASE_VIDEO_QUANTITY_USED';
export const SELECT_FILE = 'SELECT_FILE';
export const IMAGE_TO_EDIT_SELECTED = 'IMAGE_TO_EDIT_SELECTED';
export const IMAGE_TO_EDIT_DESELECTED = 'IMAGE_TO_EDIT_DESELECTED';
export const UPLOAD_EDITED_IMAGE_ATTEMPTED = 'UPLOAD_EDITED_IMAGE_ATTEMPTED';
export const UPLOAD_EDITED_IMAGE_FAILED = 'UPLOAD_EDITED_IMAGE_FAILED';
export const UPLOAD_EDITED_IMAGE_SUCCEEDED = 'UPLOAD_EDITED_IMAGE_SUCCEEDED';
export const ADOBE_INIT_ATTEMPTED = 'ADOBE_INIT_ATTEMPTED';
export const ADOBE_INIT_SUCCEEDED = 'ADOBE_INIT_SUCCEEDED';
export const ADOBE_INIT_FAILED = 'ADOBE_INIT_FAILED';
export const SAVE_ADOBE_IMAGE_ATTEMPTED = 'SAVE_ADOBE_IMAGE_ATTEMPTED';
export const SAVE_ADOBE_IMAGE_SUCCEEDED = 'SAVE_ADOBE_IMAGE_SUCCEEDED';
export const SAVE_ADOBE_IMAGE_FAILED = 'SAVE_ADOBE_IMAGE_FAILED';
export const HUB_USERS_FETCH_SUCCEEDED = 'HUB_USERS_FETCH_SUCCEEDED';
export const HUB_USERS_FETCH_FAILED = 'HUB_USERS_FETCH_FAILED';
export const HUB_USERS_FETCH_PENDING = 'HUB_USERS_FETCH_PENDING';
export const FETCH_SIGNED_URL_FAILED = 'FETCH_SIGNED_URL_FAILED';
export const SEARCH_SHUTTERSTOCK_RESET = 'SEARCH_SHUTTERSTOCK_RESET';
export const SEARCH_SHUTTERSTOCK_ATTEMPTED = 'SEARCH_SHUTTERSTOCK_ATTEMPTED';
export const SEARCH_SHUTTERSTOCK_SUCCEEDED = 'SEARCH_SHUTTERSTOCK_SUCCEEDED';
export const SEARCH_SHUTTERSTOCK_FAILED = 'SEARCH_SHUTTERSTOCK_FAILED';
export const ACQUIRE_IMAGE_ATTEMPTED = 'ACQUIRE_IMAGE_ATTEMPTED';
export const ACQUIRE_IMAGE_SUCCEEDED = 'ACQUIRE_IMAGE_SUCCEEDED';
export const ACQUIRE_IMAGE_FAILED = 'ACQUIRE_IMAGE_FAILED';
export const FETCH_CONTENT_ASSISTANCE_SETTINGS_SUCCESS = 'FETCH_CONTENT_ASSISTANCE_SETTINGS_SUCCESS';
export const FETCH_CONTENT_ASSISTANCE_SETTINGS_FAILED = 'FETCH_CONTENT_ASSISTANCE_SETTINGS_FAILED';
export const GENERATE_IMAGES_ATTEMPTED = 'GENERATE_IMAGES_ATTEMPTED';
export const GENERATE_IMAGES_SUCCESS = 'GENERATE_IMAGES_SUCCESS';
export const GENERATE_IMAGES_FAILED = 'GENERATE_IMAGES_FAILED';
export const GENERATE_IMAGE_PROMPT_ATTEMPTED = 'GENERATE_IMAGE_PROMPT_ATTEMPTED';
export const GENERATE_IMAGE_PROMPT_FAILED = 'GENERATE_IMAGE_PROMPT_FAILED';
export const GENERATE_IMAGE_PROMPT_SUCCESS = 'GENERATE_IMAGE_PROMPT_SUCCESS';
export const CANCEL_GENERATE_IMAGES = 'CANCEL_GENERATE_IMAGES';
export const SHOW_UNSAVED_IMAGES_WARNING = 'SHOW_UNSAVED_IMAGES_WARNING';
export const CLOSE_UNSAVED_IMAGES_WARNING = 'CLOSE_UNSAVED_IMAGES_WARNING';
export const UPDATE_GENERATE_PROMPT = 'UPDATE_GENERATE_PROMPT';
export const UPDATE_GENERATE_COMMAND = 'UPDATE_GENERATE_COMMAND';
export const UPDATE_GENERATE_STYLE = 'UPDATE_GENERATE_STYLE';
export const SAVE_GENERATED_IMAGES_ATTEMPTED = 'SAVE_GENERATED_IMAGES_ATTEMPTED';
export const SAVE_GENERATED_IMAGES_SUCCESS = 'SAVE_GENERATED_IMAGES_SUCCESS';
export const SAVE_GENERATED_IMAGES_FAILED = 'SAVE_GENERATED_IMAGES_FAILED';
export const SAVE_GENERATED_IMAGE_FAILED = 'SAVE_GENERATED_IMAGE_FAILED';
export const RESET_PAGE = 'RESET_PAGE';
export const CHANGE_TAB = 'CHANGE_TAB';
export const CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED = 'CHANGE_FILE_ACCESSIBILITY_REQUEST_SUCCEEDED';
export const TRACK_INTERACTION = 'TRACK_INTERACTION';
export const FETCH_SUSPENSION_STATE_ATTEMPTED = 'FETCH_SUSPENSION_STATE_ATTEMPTED';
export const FETCH_SUSPENSION_STATE_SUCCEEDED = 'FETCH_SUSPENSION_STATE_SUCCEEDED';
export const FETCH_SUSPENSION_STATE_FAILED = 'FETCH_SUSPENSION_STATE_FAILED';
export const BULK_IMAGE_IMPORT_SUCCEEDED = 'BULK_IMAGE_IMPORT_SUCCEEDED';
export const CHANGE_FOLDER_PARTITIONING_SUCCEEDED = 'CHANGE_FOLDER_PARTITIONING_SUCCEEDED';
export const CHANGE_FOLDER_PARTITIONING_ATTEMPTED = 'CHANGE_FOLDER_PARTITIONING_ATTEMPTED';
export const CHANGE_FOLDER_PARTITIONING_FAILED = 'CHANGE_FOLDER_PARTITIONING_FAILED';
export const CHANGE_FILE_PARTITIONING_SUCCEEDED = 'CHANGE_FILE_PARTITIONING_SUCCEEDED';
export const CHANGE_FILE_PARTITIONING_ATTEMPTED = 'CHANGE_FILE_PARTITIONING_ATTEMPTED';
export const CHANGE_FILE_PARTITIONING_FAILED = 'CHANGE_FILE_PARTITIONING_FAILED';
export const FETCH_TEAMS_SUCCEEDED = 'FETCH_TEAMS_SUCCEEDED';
export const FETCH_TEAMS_ATTEMPTED = 'FETCH_TEAMS_ATTEMPTED';
export const FETCH_TEAMS_FAILED = 'FETCH_TEAMS_FAILED';

// VIDEO SUBTITLES
export const FETCH_SUBTITLES_PENDING = 'FETCH_SUBTITLES_PENDING';
export const FETCH_SUBTITLES_SUCCEEDED = 'FETCH_SUBTITLES_SUCCEEDED';
export const FETCH_SUBTITLES_FAILED = 'FETCH_SUBTITLES_FAILED';
export const UPDATE_SUBTITLE_PENDING = 'UPDATE_SUBTITLE_PENDING';
export const UPDATE_SUBTITLE_SUCCEEDED = 'UPDATE_SUBTITLE_SUCCEEDED';
export const UPDATE_SUBTITLE_FAILED = 'UPDATE_SUBTITLE_FAILED';
export const UPLOAD_SUBTITLES_SUCCESS = 'UPLOAD_SUBTITLES_SUCCESS';
export const DELETE_SUBTITLE_LANGUAGE = 'DELETE_SUBTITLE_LANGUAGE';
export const EDIT_SUBTITLE_LANGUAGE = 'EDIT_SUBTITLE_LANGUAGE';
export const EDIT_SUBTITLE_FILE = 'EDIT_SUBTITLE_FILE';
export const CLEAR_SUBTITLES = 'CLEAR_SUBTITLES';

// RESOLVE
export const RESOLVE_REQUEST_PENDING = 'RESOLVE_REQUEST_PENDING';
export const RESOLVE_SUCCESS = 'RESOLVE_SUCCESS';
export const RESOLVE_FAILURE = 'RESOLVE_FAILURE';

// Folder Counts
export const FETCH_FOLDER_COUNT_SUCCEEDED = 'FETCH_FOLDER_COUNT_SUCCEEDED';
export const FETCH_FOLDER_COUNT_FAILED = 'FETCH_FOLDER_COUNT_FAILED';
export const INCREMENT_FOLDER_COUNT = 'INCREMENT_FOLDER_COUNT';
export const DECREMENT_FOLDER_COUNT = 'DECREMENT_FOLDER_COUNT';